.profile--v2--cont{
    /* background-color: blue; */
    display: flex;
    flex-direction: row;
    height: 99vh;
    padding-left: 1%;
    width: 100%;
    position: absolute;
    top:0;
    
    
}
.user--v2--cont{
    margin: 0;
  display: flex;
position: absolute;
bottom: 0;
  flex-direction: column;
/* background: tan; */
  height: 90%;
  
  width: inherit;
  padding-bottom: 100px;
  
  overflow-y: scroll;
}
.left--profile--v2--cont{
    /* background-color: rebeccapurple; */
    background-color: var(--secondary--shadow);
    /* min-width: 200px; */
    width: 250px;
    /* max-width: 300px; */
    margin-right: 1%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 5px;
    margin-top: 150px;
}
.left--profile--v2--info--cont{
    display: flex;
    /* background-color: teal; */
    flex-direction:column;
    width: 100%;
    align-items: center;
}
.profile--v2--avatar--name{
    width: 100%;
    /* background-color: thistle; */
    /* height: 120px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    flex-direction: column;
}
.profile--v2--avatar--img-n-edit{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

}
.profile--v2--avatar--cont{
    height: 101px;
    width: 101px;
    /* background-color: gray; */
    /* border-radius: 50%; */
    /* border: 2px solid var(--font); */
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 10;
}
.profile--v2--avatar--cont img{
    position: absolute;
    width: 100%;
    scale: 1.1;
}
.profile--v2--edit--btn{
    border-radius: 50px;
    
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    transform: translateY(5px) ;
    background-color: var(--bg);
    /* background-color: yellow; */
    color:  var(--font);
    padding: 2.5px 10px ;
    font-size: 12.5px;
    /* opacity: 0.5; */
    backdrop-filter: blur(0.4);
    border: 1px solid var(--font);
    z-index: 15;
    font-size: 15px;
}
.profile--v2--wallet--btn{
    border-radius: 50px;
    border: 1.5px solid var(--font);
    box-sizing: border-box;
    /* transform: translateY(5px) ; */
    background-color: var(--bg--shadow);
    color: var(--font);
    padding: 2.5px 5px ;
    font-size: 12.5px;
}
.left--profile--v2--text--cont{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: var(--font);

}
.left--profile--v2--text--cont h4{
    text-align: center;
}
.profile--v2--socials--cont{
display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 25px;
    justify-content: center;
}
.profile--v2--socials--btn{
   border-radius: 50px;
    border: 1.5px solid var(--font);
    box-sizing: border-box;
    /* transform: translateY(5px) ; */
    background-color: var(--bg--shadow);
    color: var(--font);
    padding: 2.5px 5px ;
    font-size: 12.5px;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    
}
.profile--v2--bio--cont{
    
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;

}
.profile--v2--bio--cont h3{
    opacity: 0.5;
    /* border-bottom: 1px var(--font) solid; */
    font-weight: 300;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    padding-left: 2px;
    box-sizing: border-box;
}
.profile--v2--bio--cont h3:hover{
    opacity: 1;
}

.profile--v2--bio--cont p{
    margin-top: 5px;
    opacity: 0.5;
    /* border: 1px solid var(--font--shadow); */
    /* border-radius: 0px 0px 10px 10px; */
    border-radius: 10px;
    width: 100%;
    font-size: 15px;
    min-height: 200px;
    max-height: 390px;
    padding: 5px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: var(--bg--shadow);
    }

.right--profile--v2--cont{
    /* background-color: yellow; */
    /* width: 100%; */
    width: 87%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    
    /* margin-top: 150px; */
}
.right--profile--v2--inner--box{
    margin: 0;
    /* display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
    justify-content: flex-start; */
    padding-top: 110px;
    padding-bottom: 100px;
    overflow-y: scroll;
    /* background-color: rgba(255, 255, 0, 0.249); */
}
.profile--v2--horiz--scroll{
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    height: 375px;
    /* background-color: tan; */
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;

}

.profile--v2--vert--scroll{
    display: flex;
    width: 100%;
    /* overflow-x: hidden; */
    /* overflow-y: scroll; */
    flex-wrap: wrap;
    height: fit-content;
    /* padding-bottom: 300px; */
    align-items: center;
    justify-content: center;
}
.blurring--bg--title{
    width: 60%;
    min-height: 30px;
    max-height: 50px;
    position: sticky;
    top:0;
    background-color: var(--bg);

    opacity: 1;
    color:var(--font);
    text-transform: uppercase;
    padding-left: 15px;
    margin-left: 30px;
    margin-bottom: 15px;
    border-bottom: 2px solid var(--font);
    backdrop-filter: blur(10px);
}
.blurring--bg--title h3{
    opacity: 0.8;
}
.profile--v2--horiz--scroll--purchase{
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    height: 270px;
    /* background-color: tan;  */
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;

}
.collection--holder{

}
.right--profile--v2--content--cont--row{

}
.right--profile--v2--content--cont--row--2{

}
.profile--banner{
    position: absolute;
    top: 0;
    height: 300px;
    overflow: hidden;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}
.user--banner{
    position: absolute;
    top: 0;
    height: 400px;
    overflow: hidden;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.banner--img{
    width: inherit;
    height: auto;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.banner--gradient{
    width: inherit;
    height: 100px;
    /* background: rgb(193, 99, 99); */
    background: linear-gradient(180deg, 
    rgba(41, 41, 41, 0) 0%, 
  
    var(--bg) 50%);
    position: absolute;
    bottom: 0;
    z-index: 1;
}
.banner--img img{
    width: 100%;
    height: 100%;
    position: relative;
    /* left: 0; */
    z-index: 0;
}

/* popup style */
.popup--container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg--shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 400;
    backdrop-filter: blur(4px);
    transition: all 0.1s ease;
  }
  
  .popup {
    background-color: var(--bg);
    border: 2px solid var(--bg--shadow);
    padding: 20px;
    border-radius: 40px !important;
    overflow: hidden;
    box-shadow: 0px 2px 8px var(--font--shadow);
    text-align: center;
    width: 784px;
    height: 518px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
    .popup h2{
        color: white;
        margin: none;
        padding: none;
        position: absolute;
        top: 0;
        width: 100%;
        margin-top: 40px;
        font-size: 40px;
        font-weight: bold;
  }
  .popup--cancel--btn{
    position: absolute;
    cursor: pointer;
    right:  0;
    top: 0;
    width: 60px;
    height: 60px;
    background-color: rgba(220, 31, 31, 0);
    border: none;
    color: var(--font);
    font-size: 30px;
    /* margin: 10px; */
    margin-top: 10px;
    margin-right: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* text-shadow: 0px 2px 5px var(--font--shadow); */
    transition: all 0.1s ease;
  }
  .popup--cancel--btn:hover{
    color: var(--primary);
    transition: all 0.1s ease;
  }
  .dragDropDiv{
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .popup .FL_btn__2{
    position: absolute;
    bottom: 0;
    margin: auto;
    margin-bottom: 20px;
  }






  .scale-in-center {
	-webkit-animation: scale-in-center 0.05s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.05s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


  /* ----------------------------------------------
 * Generated by Animista on 2023-12-7 13:55:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  
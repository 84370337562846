.asset--profile--card--cont{
    width: 293px;
    height: 368px;
    border-radius: 20px;
    background-color: white;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.234);
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-color: white;
    flex-basis: 293px;
    flex-grow: 0;
    flex-shrink: 0;
    padding-bottom: 12px;
}
.asset--profile--img--preview--cont{
    width: 265px;
    height: 184px;
    background-color: gray;
    border-radius: 10px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.asset--profile--img--preview--cont img{
    width: 100%;
    transform: scale(1.1);
}
.asset--profile--title{
    width: 265px;
    height: 70px;
    font-size: 24px;
    margin: 0;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
.asset--profile--description{
    width: 265px;
    height: 70px;
    /* background-color: tan; */
    margin: 0;
    font-size: 12px;
    color: gray;
    overflow: hidden;
    text-overflow: ellipsis;
}
.asset--profile--button{
    width: 265px;
    height: 45px;
    border-radius: 50px;
    background-color: #16171C;
    color: white;
    border: none;
    margin-top: 10px;
    position: absolute;
    bottom: 0;
}
.asset--profile--button p{
    color: white;
    margin: 0;
    font-size: 15px;
    font-weight: lighter;
}
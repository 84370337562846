
  
  .menu {
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 90%; */
    flex-direction: column;
    max-height: 100vh;
    position: absolute;
    width: 300px;
    background-color: var(--bg);
    box-shadow: rgba(0, 0, 0, 0.29) -1vw 2px 100px;
    z-index: 4000;
    transform: translateX(200px);
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.1s ease, height 0.3s ease-in-out;
    
  }
  
  .menu.open {
    /* opacity: 1; */
    padding-top: 100px;
    pointer-events: auto;
    height: 100%;
    opacity: 1;
    transform: translateX(0px);
    transition: transform 0.5s ease-in-out, opacity 0.1s ease;
    
    
  }
  .menu-button--cont{
    height: 125px;
    width: fit-content;
    /* background-color: #ccc; */
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
  }
  .menu-button {
    padding: 15px 25px;
    /* margin-top: 20px; */
    /* margin-right: 25px; */
    background-color: var(--bg);
    color: var(--font);
    border: none;
    cursor: pointer;
    border-radius: 8px;
    z-index: 5000;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0);
}
  
  .menu-items {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 90%;
  }
  

  /* User parts */
  .user--info--cont{
    /* background-color: red; */
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    float: inline-start;
    
  }
  .user--info{
    display: flex;
    flex-direction: column;
    /* background-color: yellowgreen; */
    align-items: center;
  }
  .user--type{
    padding: 0;
    margin: 0;
    opacity: 0.5;
    width: 100%;
    font-size: medium;
    text-align: center;
  }
  .user--name{
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    width: 250px;
    max-height: 115px; 
    text-align: center;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;

}
.FL--menu--content{
    display: flex;
    height: 50%;
    /* background-color: aquamarine; */
    flex-direction: column;
    width: 250px;
    align-items: center;
    gap: 30px;
}
.FL--menu--content > a {
    text-transform: uppercase;
    cursor: pointer;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    opacity: 0.7;
    color: var(--font);
    font-weight: 500;
    transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out;
}

.FL--menu--content > a:hover {
    color: var(--tertiary);
    opacity: 1;
    text-decoration: underline;
    transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out, text-decoration, 0.2s ease;
}
.FL--menu--bottom{
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
    opacity: 0.5;
}
.FL--menu--socials--btns{
    display: flex;
    align-items: center;

}
.FL--menu--socials--btns > button{
    border: none;
    background-color: #ffffff00;
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: var(--font);
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.1s ease-in-out;
}
.FL--menu--socials--btns > button:hover{
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
}
.FL-copyright{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: medium;
    font-weight: 400;
    cursor:default;
    opacity: 0.7;

}

.pfp--holder--sidebar{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.home--cont {
  display: inline-block;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  /* position: relative; */
  /* margin-left: 25px; */
  /* margin-right: 25px; */
  background-color: black;
  padding-top: 125px;
  /* margin-bottom: 400px; */
}
.pitches--cont{
  width: 100%;
  height: fit-content;
}
.featured--cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1180px;
  /* background-color: yellow; */
}

.featured--coll--cont--map {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  border-radius: 5px;

}

.featured--component--cont {
  display: flex;
  align-items: center;
  z-index: 10;
  position: relative;
  width: 425px;
  height: 824.5px;

  /* width: 358px;
  height: 703px; */

  border: 2px solid var(--font--shadow);
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  overflow: hidden;
}
.featured--component--cont:hover{
  border: 2px solid var(--primary);
}
.featured--youtube--video {
  display: flex;
  z-index: 12;
  /* position: absolute;
    top: 0; */
}

.featured--info--cont {
  height: 200px;
  /* width: 358px; */
  width: 425px;
  background: rgb(34, 193, 195);
  background: linear-gradient(180deg, rgba(34, 193, 195, 0) 0%, rgba(54, 54, 54, 1) 100%);
  position: absolute;
  bottom: 0;
  z-index: 16;
}



.unhidden--cont {
  position: absolute;
  top: 0;
  left: 0;

  width: 358px;
  height: 703px;


  display: flex;
}
.hidden--cont{
  display: none;
}


.featured--coll--img {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 358px;
  height: 703px; */
  width: 425px;
  height: 824.5px;
  
  opacity: 1;
  max-height: 703px;
  transition: opacity 0.1s ease-in-out
  /* , max-height 0.1s ease-in-out 0.3s */
  ;
  z-index: 14;
  overflow: hidden;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  scale: 1.5;
}

.hidden {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out
  , max-height 0.1s ease-in-out 0.3s
  ;
}



.noHov{
  opacity: 0.6;
}
.Hov{
  opacity: 1;
}


.featured--info{
  width: 100%;
  /* background-color: rgba(255, 255, 0, 0.281); */
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  display: flex;
  align-items:flex-start;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  padding-bottom: 40px;
}
.featured--title{
  max-height: 100px;
  min-height: 50px;
  display: flex;
  flex-direction: column-reverse;
}
.featured--title h2{
  font-weight: bold;
  font-size: 30px;
  margin: 0;
  cursor: pointer;
}
.featured--user{
  display: flex;
  align-items: center;
  margin: 0;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  /* background-color: yellow; */
  height: fit-content;
}
.featured--user h3{
  opacity: 0.8;
  font-weight: 400;
  font-size: 25px;
  margin: 0;
}
.featured--avatar--cont{
  display: flex;
  width: 30px;
  height: 30px;
  border: 2px solid var(--font);
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
}
.featured--avatar--cont img{
  width: 100%;
  scale: 1.1;
}
.feat--instit{
  border-radius: 2px;
}
.feat--notIns{
  border-radius: 500%;
}
.show-none{
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -100;
}
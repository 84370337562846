.search_results--container--row--scroll{
    /* height: 100%; */
    height: fit-content;
    /* background-color: rgba(10, 10, 10, 0.361); */
    display: flex;
    flex-wrap: wrap;
    /* overflow-x: scroll ; */
    flex-direction: row;
    width: 100%;
    gap: 10px;
    align-items: center;
    /* padding-bottom: 15px; */
    justify-content: center;
    /* margin-bottom: 45px; */
    padding-top:10px;
    position: relative;
}
.search_results--title--bar{
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    display: flex;
    margin-left: 10px;
    height: 30px;
    
}
.pad--bot--5{
    padding-bottom: 5px ;
}
.search_results--title--bar button{
    min-width: 100px;
    max-width: 350px;
    box-sizing: border-box;
    padding: 5px 10px;
    /* margin-right: 15px; */
    align-items: center;
    justify-content: center;
    display: flex;
    border: none;
    height: 25px;
    border-radius: 50px;
    color: var(--bg);
    background-color: var(--font);
    font-weight: bold;

}
.hide--search_results{
    display: none
}
.asset--size--switch--cont{
    position: fixed;
    top: 200;
    right: 0;
    /* margin-right: 10px; */
    width: 300px;
    /* background-color: white; */
    z-index: 1000;
    transform: translateY(-25px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: var(--font--shadow);
    

}
.result--title{
    position: relative;
}
.size--btn--cont{
    display: flex;
    width: 200px;
    justify-content: space-between;
}
.size--btn--cont button{
    opacity: 0.8;
    font-weight: bold;
    color: var(--bg);
    background-color: var(--font--shadow);
    border: none;
    padding: 5px 8px;
    border-radius: 5px;
    
}
.size--btn--cont button:hover{
    opacity: 1;
    color: var(--bg);
    background-color: var(--font);
}
.search--results--footer{
    width: 100%;
}
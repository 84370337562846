.logo-cont {
    color: var(--font);
    font-size: xx-large;
    position: relative;
    /* top: 0%; */
    /* left: 0; */
    width: 150px;
    background-color: var(--bg--shadow);
    backdrop-filter: saturate(180%) blur(1.5px);
    /* margin: auto; */
    height: 86px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3001;
    /* margin-top: 200px; */
    transform: translateX(50px);

}
.home--tool--tip{
    transform: scale(0.7);
}
.logo-cont img{
    width: 100%;
    align-self: center;
    justify-self: center;
    position: absolute;
    margin: 0.25rem;
    /* margin-top: 0.25rem; */
}
/* .logo-btn {
    background-color: #ffffff00;
    border: none;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    z-index: 902;

}
.logo-btn  strong{
    color: red;
} */
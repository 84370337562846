.about--cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 5vh;
    padding-bottom: 15vh;
    z-index: 20;
    width: 100%;
    overflow-y: scroll;
    padding-top: 100px;
  }
  
  .about--h2 {
    font-size: 36px !important;
    font-weight: 100 !important;
    margin-left: 40px;
  }
  
  .about--h2--center {
    font-size: 36px !important;
    font-weight: 100 !important;
    text-align: center;
  }
  .about--p {
    margin-left: 8vh;
    margin-right: 8vh;
    margin-top: 1vh;
    line-height: 32px;
    z-index: 20;
  }
  
  .accordion--cont {
    /* border-top: 1px solid #ccc; */
    padding: 40px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  
  }
  .accordion--content{
    margin-bottom: 1rem;
    display: flex;
    align-items: left;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 20px;
    opacity: 0.7;
    text-align: left;
  }
  .mar--2{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .about--horiz--img--cont{
    display: flex;
    flex-flow: row;
    overflow-y: show;
    /* overflow-x: scroll; */
    justify-content: center;
    gap: 150px;
    align-items: center;
    width: 100%;
  }
    .profile--collection--card--cont{
    height: 270px;
    width: 370px;
    border-radius: 20px;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.234);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: white;
    flex-basis: 370px;
    flex-grow: 0;
    flex-shrink: 0;
}
.profile--collection--card--title--cont{
    height:100px;
    width:370px;
    position: absolute;
    top: 0;
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    /* border-radius: 10px; */
    border-bottom: 1px solid rgba( 255, 255, 255, 0.18 );
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 40;
    overflow: hidden;
}
.profile--collection--card--title--cont h1{
    color: black;
    font-size: 25px;
    text-transform: uppercase;
}
.profile--collection--card--img--cont{
    background-color: rgba(128, 128, 128, 0.186);
    width: 350px;
    height: 250px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    
}

.profile--collection--card--img--cont img{
position: absolute;
left: 0;
width: 100%;
transform: scale(1.1);

}


.collection--carousel {
    display: flex;
    align-items: center;
    /* background-color: aquamarine; */
    width: 100%;
    overflow: hidden;
    justify-content: space-around;
    position: relative;
  }
  
  .collection--carousel-btn {
    /* font-size: 1.5rem; */
    cursor: pointer;
    height: 100%;
    border: none;
    position: absolute;
    z-index: 100;
    min-width: 50px;
    color: var(--font);
    font-size: 40px;
  }
  .carousel-btn-r{
    background: var(--bg--shadow);
    background: linear-gradient(90deg, rgba(34,193,195,0) 0%, var(--bg--shadow)50%);
    right: 0;
    opacity: 0.7;

  }
  .carousel-btn-l{
    background: var(--bg--shadow);
    background: linear-gradient(-90deg, rgba(34,193,195,0) 0%, var(--bg--shadow)50%);
    left: 0;
    opacity: 0.7;
  }
  

  .collection--carousel-item {
    
    min-width: 100%;
    /* transform: scale(0.8); */
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-container {
    position: relative;
    width: 80%;
    margin: auto;
    overflow: hidden;
}

.carousel {
    display: flex;
    width: 100%;
    transition: transform 0.05s ease-in-out;
}


  
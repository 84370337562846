
input[type="search"]{
    border: none;
    outline: none;
    background-color: rgba(15, 15, 15, 0.084);
  
  }
.v2--search--cont{
    background-color: var(--bg);
    color: var(--font);
    border-radius: 500px;
    /* width: 60%; */
    width: 800px;
    height: 50px;
    margin: auto;
    border: var(--font) 2px solid !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
    margin: auto;
    opacity: 0.7;
    transition: all 0.3s ease;
    /* padding-left: 2px; */
}
.v2--search--cont:hover{
    opacity: 1;
    transition: all 0.3s ease;
}
.v2--search--input{
    border-radius: 500px;
    width: 100%;
    height: 50px;
    border: none;
    position: absolute;
    left: 0;
    padding-left: 30px;
    /* margin-left: 0.5px; */
    font-family: var(--font--fam);
    font-size: 20px;
    opacity: 0.6;
    border: black 1px solid;
    color: var(--font);
}
.v2--search--button{
    color: black;
    width: 12%;
    background-color: var(--font);
    height: 46px;
    position: absolute;
    right: 2px;
    border-radius: 500px;
    border: none;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.v2--search--input:focus{
    color: var(--tertiary);
    border: var(--tertiary) 1.5px solid;
    /* color: var(--primary); */
    /* border: none; */
    /* border: var(--primary) 1.5px solid; */
    opacity: 1;
    box-shadow: 0px 3px 5px var(--tertiary--shadow);
    font-weight: bold;
    transition: box-shadow 0.7s ease-in-out, opacity 0.3s ease, font-weight 0.1s ease-in-out;
}
.v2--search--input:focus .v2--search--button{
    background-color: var(--tertiary);

}
.v2--search--input:not(:focus) {
    /* color: var(--tertiary);
    border: var(--tertiary) 1.5px solid; */
color: var(--font);
border: var(--primary) 1.5px solid;

    box-shadow: none;
    font-weight: regular;
    opacity: 0.5;
    transition: box-shadow 0.4s ease-in-out, opacity 0.3s ease, font-weight 0.05s ease-in-out;
  }

.register--content--cont{
    width: 670px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}
.input--cont{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    /* width: 500px; */
    /* background-color: rebeccapurple; */
    margin-bottom: 15px;
    
}
.input--cont section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* width: 500px; */
    /* background-color: rebeccapurple; */
    /* margin-bottom: 15px; */
    position: relative;
    height: 80px;
    /* background-color: tan; */
    justify-content: center;
}
.input--cont section input{
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 50px;
    /* border: black 1px solid; */
   
}
.input--cont aside{
    display: flex;
    align-items: center;
    justify-content:flex-start;
    flex-direction: column;
    overflow: hidden;
}
.sub--input--cont{
    position: relative;
    width: 250px;
    height: 80px;
    overflow: hidden;
    padding-left: 5px;
}
.sub--input--cont label{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    color: 000000;
  font-size: 18px;
  font-weight: 600;
}
.sub--input--cont label input{
    width: 100px;
    height: 47px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 500px;
  border: solid 1px var(--font);
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 18px;
  color: var(--font);
}
.fir--las--name--input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 663px;

}
.subTier--info--cont{
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    height: 80px;
    flex-direction: column;
    font-size: 10px;
}
.subTier--info--cont p{
    font-size: 15px;
}
.FL_Input_check{
    flex-direction: row !important;
}
.FL_Input_check input{
    border-radius: 5px !important;
}
.instructions {
    font-size: 12.5px;
    /* border-radius: 0.5rem; */
    background: #00000082;
    color: #fff;
    padding: 5px;
    position: absolute;
    transform: translateX(-310px);
    
    max-width: 300px;
    height: 70px;
    margin: 5px;
    /* border: red 1px solid; */
    border-radius: 10px;
    box-sizing: border-box;
}

.special--chars{
    color: #cecece;
}
.special--chars span{
    font-weight: bold;
}
.register--link{
    color: lightcoral;
    text-decoration: underline;
    cursor: pointer;
}
.FL--footer--cont--cont{
    display: flex;
    align-items: center;
    flex-direction: column;
    /* background-color: rgb(33, 187, 36); */
    height: 400px;
    flex-wrap: wrap;
    gap: 25px;
    padding-top: 25px;
    position: relative;
    /* position: absolute;
width: 100%;
bottom: 0; */
    margin-top: 100px;
}
.FL--footer--content--cont{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 200px;
    flex-wrap: wrap;
/* background-color: white; */
}

.FL--footer--box--cont{
    width: 15%;
    height: 200px;
    display: flex;
    flex-flow: column;
}
.FL--footer--box--cont h3{
    font-family: var(--font--fam);
    margin: 0;
    text-transform: uppercase;
    cursor: pointer;
}
.FL--footer--box--cont h3 strong{
    color: var(--primary);
}
.temp--6{
    /* background-color: indigo; */
    width: 100%;
    height: 20px;
    /* position: absolute;
    top: 0; */
    text-align: center;
    opacity: 0.2;

}
.temp--6 a{
    color: var(--font);
}


.FL--footer--box--cont > a {
    /* text-transform: uppercase; */
    cursor: pointer;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    opacity: 0.7;
    color: var(--font);
    font-weight: 500;
    transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out;
}

.FL--footer--box--cont > a:hover {
    color: var(--tertiary);
    opacity: 1;
    text-decoration: underline;
    transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out, text-decoration, 0.2s ease;
}
.FL--footer--box--cont > a > strong{
 font-weight: 550;
}
.FL--footer--box--cont > a:hover > strong{
    /* color: black */
    color: var(--font);
   }
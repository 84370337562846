.sidebar--menu{
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 90%; */
    flex-direction: column;
    max-height: 100vh;
    position: absolute;
    
    background-color: black;
    /* box-shadow: rgba(0, 0, 0, 0.29) -1vw 2px 100px; */
    z-index: 100;
    transform: translateX(200px);
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.1s ease, height 0.3s ease-in-out;
}
.sidebar--menu.small{
    padding-top: 100px;
    pointer-events: auto;
    height: 100%;
    opacity: 1;
    transform: translateX(0px);
    width: 70px;
    transition: transform 0.5s ease-in-out, opacity 0.1s ease, width 0.1s ease;
}
.sidebar--menu.open{
    padding-top: 100px;
    pointer-events: auto;
    height: 100%;
    opacity: 1;
    transform: translateX(0px);
    width: 250px;
    transition: transform 0.5s ease-in-out, opacity 0.1s ease, width 0.1s ease;
    
}

.sidebar--menu-button--cont{
    height: 50px;
    width: fit-content;
    /* background-color: #ccc; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .sidebar--menu-button {
    padding: 5px;
    margin-top: 39px;
    margin-left: 10px;
    background-color: var(--bg);
    color: var(--font);
    border: none;
    cursor: pointer;
    border-radius: 8px;
    z-index: 5000;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0);
    position: absolute;
    /* top: 0; */
    left: 0;
    z-index: 1000;
}

  /* User parts */
  .sidebar--user--info--cont{
    /* background-color: red; */
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    float: inline-start;
    
  }
  .sidebar--user--info{
    display: flex;
    flex-direction: column;
    /* background-color: yellowgreen; */
    align-items: center;
  }
  .sidebar--user--type{
    padding: 0;
    margin: 0;
    opacity: 0.5;
    width: 100%;
    font-size: medium;
    text-align: center;
    color: var(--font);
  }
  .sidebar--user--name{
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    width: 250px;
    max-height: 115px; 
    text-align: center;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--font);

}
.sidebar--menu--content--small{
    display: flex;
    height: 85%;
    /* background-color: aquamarine; */
    flex-direction: column;
    width: 70px;
    align-items: center;
    gap: 30px;
}
.sidebar--menu--content--small > a {
    text-transform: uppercase;
    cursor: pointer;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    opacity: 0.7;
    color: var(--font);
    font-weight: 500;
    max-height: 30px;
    transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out;
}

.sidebar--menu--content--small > a:hover {
    color: var(--primary);
    opacity: 1;
    text-decoration: underline;
    transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out, text-decoration, 0.2s ease;
}

.sidebar--menu--content--large{
    display: flex;
    height: 69%;
    /* background-color: aquamarine; */
    flex-direction: column;
    width: 250px;
    align-items: center;
    gap: 30px;
    text-align: left;
}
.sidebar--menu--content--large > a {
    text-transform: uppercase;
    cursor: pointer;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    opacity: 0.9;
    color: var(--font);
    font-weight: 500;
    transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out;
    text-align: left;
    width: 60%;
    max-height: 30px;
    overflow: hidden;
    flex-direction: row;
    position: relative;
    padding-left: 35px;
}

.sidebar--menu--content--large > a:hover {
    color: var(--primary);
    opacity: 1;
    text-decoration: underline;
    transition: opacity 0.1s ease-in-out, color 0.2s ease-in-out, text-decoration, 0.2s ease;
}
.sidebar--menu--icon{
    height: 30px;
    width: 30px;
    margin: 0;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
}
.sidebar--menu--bottom{
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
    opacity: 0.5;
    color: var(--font);
}
.sidebar--menu--socials--btns{
    display: flex;
    align-items: center;

}
.sidebar--menu--socials--btns > button{
    border: none;
    background-color: #ffffff00;
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: var(--font);
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.1s ease-in-out;
}
.sidebar--menu--socials--btns > button:hover{
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
}
.sidebar--copyright{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: medium;
    font-weight: 400;
    cursor:default;
    opacity: 0.7;

}

.progress-container {
    width: 300px;
    height: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ddd;
    border: 1px solid rgba(255, 255, 255, 0.308);
  }
  
  .progress-bar {
    width: 0;
    box-sizing: border-box;
    moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.052);
    /* background-color: var(--primary); */
    transition: all 0.5s;
    border-right: 1px solid rgba(255, 255, 255, 0.308); ;
    background: linear-gradient(107deg, var(--tertiary) 0%, var(--tertiary) 47%, rgba(242,242,242,1) 50%, var(--tertiary) 53%, var(--tertiary) 56%, var(--tertiary) 100%);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
}
@keyframes gradient {
    0% {
        background-position: 100% 50%;
    }

    50% {
        background-position: 50% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
  
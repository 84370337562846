/* .toast-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-color: var(--bg);
  } */

  /* Toast.css */
.toast {
    background-color: #333; /* Background color of the toast */
    color: #fff; /* Text color of the toast */
    border: 1px solid #555; /* Border color of the toast */
    border-radius: 4px; /* Rounded corners */
    padding: 10px; /* Padding inside the toast */
    margin: 10px 0; /* Margin between toasts */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .toast button {
    background: none;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  
  /* Example close button style */
  .toast button:hover {
    color: #ff5733; /* Change color on hover */
  }
  
  .toast-container {
    position: fixed;
    top: 20px; /* Adjust the top position as needed */
    right: 20px; /* Adjust the right position as needed */
    z-index: 9000; /* Ensure the toasts appear above other content */
    width: 300px; /* Adjust the container width as needed */
  }
  
  /* Add animations or transitions for smoother appearance/disappearance */
  .toast {
    animation: slideIn 0.5s ease, fadeOut 0.5s ease 2.5s forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  

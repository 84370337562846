.user--card--grid{
    display: flex;
    height: 400px;
    width: 700px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    /* background-color: thistle; */
    overflow: hidden;
    position: relative;
    z-index: 3;
    background-color: var(--bg);
margin: 0;
    border-radius: 20px;
    border: 2px solid rgba(0, 0, 0, 0.303);

}
.user--card--cont--grid{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    z-index: 4;
    /* background-color: aqua; */
    box-shadow: 2px 2px 5px var(--bg--shadow);
    width: 100%;
}
.user--card--collection--display{
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    height: 420px;
    width: 700px;
    /* position: absolute; */
    /* bottom: 0; */
    z-index: 6;
    /* gap: 10px; */
    
}
.user--card--title--cont{
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: yellow; */
    height: 150px;
    width: inherit;
    gap: 25px;
    position: absolute;
    top: 0;
    z-index: 6;
    background: var(--bg--shadow);
    backdrop-filter: blur(10px);
    padding: 20px;
    border-bottom: 2px solid var(--bg--shadow);
    font-size: 30px
      }
      .user--card--title--cont h2{
        font-size: 50px;
        font-weight: 800;
      }
      .user--card--img--cont{
        height: 480px;
        width: 700px;
        display: flex;
        background-color: var(--secondary--shadow);
        transform: 
        /* translateY(-115px) */
         scale(1.1);
        /* position: absolute; */
        /* bottom: 0; */
        z-index: inherit;
        position: relative;
      }
      .user--card--img--cont img{
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
      }

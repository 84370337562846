.splitter--cont{
    width: 100%;
    /* background-color: plum; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4px;
}
.splitter{
    width: 97%;
    background-color: var(--font);
    height: 2px;
    border-radius: 5px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: auto;
    margin-bottom: auto;
}
.team--member--cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 225px;
    /* background-color: thistle; */
    opacity: 0.8;
    transition: opacity 0.2s ease,transform 0.2s ease;
}

.team--member--cont:hover {
    transform: scale(1.3);
    opacity: 1;
    transition: opacity 0.2s ease,transform 0.2s ease;
}

.team--img--cont {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    border-radius: 28px;
    width: 150px;
    height: 150px;
    position: relative;
}

.team--img--cont img {
    left: 0;
    position: absolute;
    width: 100%;
    transform: scale(1.1);
}

h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 20px;

}

h4 {
    font-weight: 500;
    text-transform: uppercase;
    transform: translateY(-15px);
    opacity: 0.5;
}
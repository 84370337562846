.pfp--cont{
    background-color: gray;
    border-radius: 50px;
    border: 2px solid var(--font);
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 10;
}
.pfp--size--1{
    height: 100px;
    width: 100px;
}
.pfp--size--1 img{
height: 100px;
}
.pfp--size--2{
    height: 50px;
    width: 50px;
}
.pfp--size--2 img{
height: 50px;
}
.pfp--size--3{
    height: 25px;
    width: 25px;
}
.pfp--size--3 img{
    height: 25px;
}
.pfp--cont img{
    z-index: 10;
    position: absolute;
    width: 100%;
    scale: 1.05;
}
.default--img--cont{
    scale: 1.15 !important;
    /* transform: translateY(3px); */
}

.instit--1{
    border-radius: 5px;
}
.instit--2{
    border-radius: 3px;
}
.instit--3{
    border-radius: 1.5px;
}

.imgCoveringCont{
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 0, 0.306); */
    z-index: 11;
    color: rgba(255, 255, 255, 0);
    font-size: 0.001px;
    position: absolute;
}
/* pitch 1 start */
.pitch--container {
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pitch--1--cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background-color: yellowgreen; */
  flex-direction: column;
  /* flex-flow: ; */
  justify-content: center;
}

.pitch--1--cont h1 {
  color: var(--font);
}

.pitch--logo--cont {
  height: 400px;
  position: relative;
  margin: 50px 0px 0px 0px;
  /* background-color: bisque; */
  width: 696px;
}

.pitch--logo--cont img {
  height: 400px;
  position: absolute;
  left: 0;
}


.pitch--1--content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  /* margin-bottom: 50px; */
  gap: 100px;

}

.pitch--1--content p {
  font-size: 30px;
  width: 400px;
  font-weight: 600;
  font-family: var(--font--fam);
}

.pitch--1--content p span {
  font-size: 30px;
  /* width: 400px; */
  color: var(--primary);
  font-weight: 700;
}

.pitch--1--img--cont {
  font-size: 15px !important;
}

.pitch--1--img--cont img {
  height: 500px;
}

.pitch--1--img--cont p {
  font-size: 15px !important;
}

/* pitch 1 end */

/* pitch 2 start */
.pitch--2--cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background-color: yellowgreen; */
  flex-direction: column;
  /* flex-flow: ; */
  justify-content: center;
}

.pitch--2--cont h1 {
  color: var(--font);
  width: 100%;
  margin-left: 100px;
}

.pitch--2--content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
  /* background-color: yellow; */
}

.pitch--2--problem {
  width: 25%;
  min-height: 350px;
  max-height: 500px;
  /* background-color: rebeccapurple; */
  /* margin: auto; */
  padding: 10px;
  box-sizing: border-box;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.pitch--2--problem h1 {
  color: var(--primary);
  margin: 0;
  font-size: 38px;
  margin-bottom: 20px;
}

.pitch--2--problem p {
  font-size: 30px !important;

}

.pitch--2--problem strong {
  font-weight: 900
}

.pitch--2--problem:hover {
  opacity: 1;
}

/* .pitch--2--problem--splitter{
    width: inherit;
    background-color: var(--font);
    height: 10px;
    font-size: 0.1px;
    color: white;
    border: 2px var(--font) solid;
} */
/* pitch 2 end */


/* pitch 3 start */
.pitch--3--cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background-color: yellowgreen; */
  flex-direction: column;
  /* flex-flow: ; */
  justify-content: center;
}

.pitch--3--cont h1 {
  color: var(--font);
  width: 100%;
  margin-left: 100px;
}

.pitch--3--content {
  display: flex;
  flex-direction: row-reverse;
  gap: 70px;
}

.pitch--3--talking--points {
  height: 600px;
  width: 700px;
  /* background-color: rgba(153, 205, 50, 0.539); */
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.pitch--3--talking--points p {
  width: 700px;
  font-size: 30px;
  opacity: 0.5;
  font-family: var(--font--fam);
  font-weight: 200;
  transition: all ease-in-out 0.3s;
}

.pitch--3--talking--points p:hover {
  opacity: 1;
}

/* pitch 3 end */

/* pitch 4 start */
.pitch--4--container {
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.pitch--4--content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;

}

.redirect--about--btn {
  display: flex;
  width: 225px;
  height: 50px;

  border: none;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  z-index: 10;
  /* margin: 25px; */
  font-weight: bold;
  font-size: 20px;
  color: var(--font);
  background-color: var(--primary);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.redirect--about--btn:hover {
  box-shadow: 0px 4px 2px var(--primary--shadow);
  text-shadow: 0px 2px 2px var(--font--shadow);
  transform: scale(1.01);
  transition: all 0.3s ease-in-out;
}


/* Featured Collection Large */
.featured--video--lg--cont--home {
  width: 100%;
  height: 800px;
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  margin-bottom: 10%;
}

.featured--carousel {
  display: flex;
  align-items: center;
  /* background-color: aquamarine; */
  width: 100%;
  overflow: hidden;
  height: 800px;
  justify-content: space-around;
  position: relative;
}

.featured--carousel-btn {
  /* font-size: 1.5rem; */
  cursor: pointer;
  height: 100%;
  border: none;
  position: absolute;
  z-index: 100;
  min-width: 50px;
  color: var(--font);
  font-size: 40px;
}

.carousel-btn-r {
  background: var(--bg--shadow);
  background: linear-gradient(90deg, rgba(34, 193, 195, 0) 0%, var(--bg--shadow)50%);
  right: 0;
  opacity: 0.7;
  width: 200px;

}

.carousel-btn-l {
  background: var(--bg--shadow);
  background: linear-gradient(-90deg, rgba(34, 193, 195, 0) 0%, var(--bg--shadow)50%);
  left: 0;
  opacity: 0.7;
}


.featured--carousel-item {

  /* width: 80%; */
  margin: auto;
  width: 1230px;
  /* transform: scale(0.8); */


  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

/* .carousel-container {
    position: relative;
    width: 80%;
    margin: auto;
    overflow: hidden;
} */

.carousel {
  display: flex;
  width: 1430px;
  flex-direction: row;
  /* justify-content: center; */
  /* gap: 50px; */
  transition: transform 0.05s ease-in-out;
  margin-right: 100px;
}

/* Style for the active item */
.active-item {
  opacity: 1 !important;
  transition:
    opacity 0.5s ease-in-out;
  /* border: 2px solid #34c1c3; Add your desired border style for the active item */
  /* Additional styles for the active item */
}

.inactive-item {
  opacity: 0.3 !important;
  transition:
    opacity 0.5s ease-in-out;
}


.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-6 16:29:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-12-6 16:34:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.upload--landing--cont {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 20px;
    position: relative;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
}

.upload--landing--button {
    padding: 10px 40px;
    background-color: black;
    border: none;
    border-radius: 500px;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.form-feedback {
    margin-top: 10px;
}

.success-message {
    color: green;
}

.error-message {
    color: red;
}

.collection--upload--page--content--cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    gap: 25px;
    margin-top: 25px;
}

.asset--upload--page--content--cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    gap: 15px;
    margin-top: 20px;
}

.asset--upload--left {
    display: flex;
    align-items: center;
    /* background-color: rebeccapurple; */
    flex-flow: column;
}
.collection--upload--left {
    display: flex;
    align-items: center;
    /* background-color: rebeccapurple; */
    flex-flow: column;
    margin-top: 20px;
}
.collection--upload--right {
    display: flex;
    flex-flow: column;
    gap: 10px;
    margin-top: 5px
}
.div--here{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height:400px;
    margin-top: 20px;
}
.another--div--named--here{
    margin-top: 10px;
}
.left--label {
    color: 000000;
    font-size: 18px;
    font-weight: 600;
    width: 693px;
}
.asset--upload--right {
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.image--preview--cont {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    /* width: 693px; */
    height: 500px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #36363638;
}



.upload--right--price-n-date--cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 0;
}

.human--made--verification--cont {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.human--made--verification--cont h5 {
    color:var(--font);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.human--made--verification--cont p {
    color:var(--font) ;
    font-size: 12px;
    /* font-weight: 600; */
    margin: 0;
}

.human--made--verification--cont a {
    color: #CD1C10;
    border-bottom: 1px #CD1C10 solid;
}

.checkbox--cont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.human--made--description {
    font-size: 15px !important;
    color: var(--font) !important;
    opacity: 1;
    width: 650px;
    height: 67px;
    margin: 0;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    text-align: left;
}

.button--width--713 {
    width: 713px;
}
.submit--asset--btn--cont{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 500px;
    height: 75px;
    gap: 1px;
}
.submit--asset--btn--cont .FL_btn__2{
    margin-top:0px !important;

}
.button--width--413 {
    width: 413px;
    
    /* height: 51px; */
    /* position: absolute; */
    /* bottom: 0; */

}
.upload--label {
    /* color: 000000; */
    font-size: 18px;
    font-weight: 600;
    width: 503px;
    /* position: absolute; */
    /* top: 0; */
    /* margin-top: 10px; */
    height: 24px;
    text-align: center;
}





  

  
.image-preview{
    display: flex;
    width: 100VW;
    background-color: rgba(128, 128, 128, 0.294);
    height: 250px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 50px;
}



.image-preview--title--2{
    position: absolute;
    top: 0;
    text-align: center;
    gap: 0px;
}


  .create--coll--page{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    margin-top: 90px;
}
.create--coll--page h2{
    font-size: 50px;
    margin-bottom: 15px;
}
.create--coll--page h4{
    margin: 0;
    transform: none;
}


.response--progress--cont{
    display: flex;
    align-items: center;
    width: 600px;
    justify-content: center;
    transition: all 1s;
}
.create--coll--reset--btn{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 50px;
    margin-top: 120px;
    background-color:  black;
    border: none;
    color: white;
    width: 200px;
    height: 50px;
    border-radius: 50px;
    font-family: var(--font--fam);
    font-size: 15px;
    font-weight: 500;
    transition: box-shadow 0.3s ease, text-shadow 0.3s ease, scale 0.3s ease;
    z-index: 1000;
  }
  
  .create--coll--reset--btn:hover{
    transform: scale(1.05);
    box-shadow: rgba(255, 0, 0, 0.893) 0px 1px 6px;
    text-shadow: rgba(2, 2, 2, 0.533) 0px 1px 2px;
    color: rgba(255, 0, 0, 0.893)
  
}

.collection--form--container{
    /* background-color: yellow; */
    width: 100%;
    min-height:400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 5px;
    /* margin-top: 300px; */
    z-index: 10;
    
}
.collection--form--title{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    position: absolute;
    top: 0;
    /* width: 600px; */
    width: 100%;
    /* background-color: rgb(236, 236, 236); */
    background-color: black;
    height: 60px;
    /* overflow: hidden; */
    box-shadow: 0px 2px 4px #00000051;
    z-index: 20;
    padding-right: 10px;
    transition: all ease 0.5s;
    
}
.collection--form--title h3{
    position: absolute;
    top: 0;
    margin: 0;
}
.form--title--btn{
    /* margin-top: 20px; */
    border: none;
    border-bottom: #ffffff 2px solid;
    color: #ffffff;
    opacity: 0.5;
    transition: all ease 0.5s;
    width: 40%;
    height: 60px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-left: 5%;
    margin-right: 5%;
    background-color: rgb(0, 0, 0);
}
.form--title--btn:hover{
    /* margin-top: 20px; */
    opacity: 1;
    
    transition: all ease 0.5s;
    
}
.form--title--btn--selected{
    /* margin-top: 20px; */
    border-bottom: var(--tertiary) 2px solid;
    color: var(--tertiary);
    opacity: 1;
    
    transition: all ease 0.5s;
}
.hidden{
    opacity: 0;
    position: absolute;
    left: 0 ;
    right: 0;
    z-index: -400;
    width: 1px;
    height:1px;

}


.collection--form--asset--imgcont{
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    /* width: 693px; */
    /* height: 400px; */
    border-radius: 20px;
    overflow: hidden;
    background-color: #36363638;
    height: 400px;
}



  .collection--form--asset--imgcont img{
    /* position: absolute;
    left: 0;
    width: 100%;
    filter:blur(2px); */
    /* transform: scale(1.01); */
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
  }

  .pulsate-fwd {
	-webkit-animation: pulsate-fwd 0.25s ease-in-out both;
	        animation: pulsate-fwd 0.25s ease-in-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-2 13:7:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.01);
              transform: scale(1.01);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.01);
              transform: scale(1.01);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 400;
  }
  
  .popup {
    background-color: var(--bg);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  .popup button {
    margin: 5px;
  }
  

  .featured--radios{
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-left: 30px ;
    gap: 2px;
    /* background-color: var(--secondary); */
  }
  .featured--radios label{
    /* border: 1px solid var(--font); */
    background-color: var(--secondary--shadow);


  }
  .collection--radio{
    color: var(--secondary);
    background-color: var(--primary--shadow);
  }

  /* Add these styles to your CSS file or use a style tag in your component */

/* Add these styles to your CSS file or use a style tag in your component */

.radio-label {
    display: block;
    position: relative;
    padding-left: 30px; /* Adjust the padding to your preference */
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 16px; /* Adjust the font size to your preference */
}

.radio-custom {
    position: absolute;
    top: 0;
    left: 20px;
    height: 20px; 
    width: 20px;
    background-color: var(--secondary--shadow); 
    border: 4px solid var(--secondary); 
    border-radius: 50%; 
    transition: background-color 0.3s, border-color 0.3s; 
    box-sizing: border-box;
}

.radio-label input {
    opacity: 0; 
}

/* Change the background color and border color when checked */
.radio-label input:checked + .radio-custom {
    background-color: var(--primary); 
    border-color: var(--secondary--shadow); 
}
.featured--form--cont{
    display: flex;
    align-items: flex-start;
background-color: tan;
flex-direction: column;
justify-content: flex-start;
width: 600px;
}

/* Profile Picture Updating */


.home--coll--card--cont{
    background-color: var(--bg);
    border-radius: 25px;
    border: solid 1.5px rgba(0, 0, 0, 0.504);
    height: 450px;
    width: 335px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: 335px;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
}
.home--coll--card--cont h2{
    padding: 0;
    margin: 0;
    text-align: left;
    width: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.coll--card--img{
    height: 230px;
    width: 315px;
    overflow: hidden;
    border-radius: 15px;
    /* position: relative; */
    background-color: rgba(0, 0, 0, 0.391);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
}
.coll--card--img img{
    position: absolute;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    transform: scale(1.3);
}
.coll--card--img--1{

  overflow: hidden;
  border-radius: 15px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.391);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 12;
}
.coll--card--text{
  margin-top: 170px;
  width: 315px;  
    height: 130px;
    overflow: hidden;
    /* background-color: rgba(210, 180, 140, 0.371); */
    padding: 0;
    /* margin: 0; */
    flex-direction: column;
    position: relative;
}

.coll--card--text p{
    padding: 0;
    margin: 0;
    /* height: 20px; */
    width: 315px;
    text-overflow: ellipsis;
    font-size: 20px;
    color: rgb(0, 0, 0);
    opacity: 0.7;
}
.coll--card--btn{
    background-color: rgb(27, 27, 27);
    position: absolute;
     bottom: 10px;
    border: none;
    color: white;
    width: 315px;
    height: 50px;
    border-radius: 50px;
    font-family: var(--font--fam);
    font-size: 20px;
    font-weight: 300;
    margin-top: 20px;
    transition: box-shadow 0.3s ease, text-shadow 0.3s ease, scale 0.3s ease;
}

.coll--card--btn:hover{
    transform: scale(1.01); /* Increase the scale on hover */
    box-shadow: rgba(84, 84, 84, 0.845) 0px 1px 2px;
    text-shadow: rgba(212, 210, 210, 0.533) 0px 1px 2px;
}
.active {
    transform: scale(0.5); /* Decrease the scale when active */
  }





  .coll--card--2--cont{

  }
  .coll--card--row{
    padding: 13px;
    background-color: rgb(255, 255, 255);
    width: 1318px;
    height: 367px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 20px;
    border: 2px solid rgba(0, 0, 0, 0.303);
  }
  .coll--card--grid{
    padding: 13px;
    background-color: rgb(255, 255, 255);
    width: 624px;
    height: 367px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 20px;
    border: 2px solid rgba(0, 0, 0, 0.303);
  }
  .coll--card--row--cont{
    background-color: rgba(164, 164, 164, 0.161);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  .coll--card--img--cont{
    display: flex;
    position: absolute;
    bottom: 0;
    background-color: rgba(50, 50, 50, 0.277);
    width: 100%;
    height: 204px;
    border-radius: 10px;
    overflow: hidden;
    
  }
  .coll--card--grid--cont{
    /* background-color: rgba(164, 164, 164, 0.161); */
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    position: relative;
    overflow: hidden;
  }
  .coll--card--title--cont{
display: flex;
align-items: center;
justify-content: center;
/* background-color: yellow; */
height: 163px;
width: 100%;
gap: 25px;

  }
  .coll--card--title--cont h2{
    margin: 0;
    font-size: 35px;
    width: 65%;
    /* background-color: aliceblue; */
    font-weight: 500;
    height: 80%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .coll--card--img--cont{
    display: flex;
    position: absolute;
    bottom: 0;
    background-color: rgba(50, 50, 50, 0.277);
    width: 100%;
    height: 204px;
    border-radius: 10px;
    overflow: hidden;
  }
  .coll--card--img--title--cont{
    width: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    padding-left: 10px;
    bottom: 0;
    height: 80px;
    background: rgb(4, 4, 4);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.462) 100%);

}
  
  .coll--card--img--title--cont h2{
    font-size: 25px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.442);
  }

  .coll--avatar--cont{
    width: 84px;
    height: 84px;
    /* border-radius: 5px; */
    border: 2px black solid;
    
  }
  .instit--shape{
    border-radius: 5px;
  }
  .indiv--shape{
    border-radius: 50px;
  }

  .asset--card--cont{
    display: flex;
    
    position: relative;
    width: fit-content;
    overflow: hidden;
    flex-direction: column;
    border-radius: 5px;
    border: 2px solid rgba(255, 255, 255, 0.585);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.268);
    transition: all ease 0.25s;
    flex-basis: content;
  flex-shrink: 0;
  flex-grow: 0;
  }
  .asset--card--size--1{
    height: 250px;
  }
  .asset--card--size--2{
    height: 350px;
  }
  .asset--card--size--3{
    height: 450px;
  }
  .asset--card--img{
    /* position: absolute; */
    /* left: 0; */
    height: 100%;
    width: 100%;
    position: relative;
  }
  .asset--card--img img{
    /* position: absolute;
    left:0; */
    scale: 1.01;
  }

  .asset--card--info--cont{
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content:  center;
    flex-direction: column;
    border-top: 2px solid rgba(255, 255, 255, 0.151);
    background-color: rgba(255, 255, 255, 0.31);
    backdrop-filter: blur(9px);
    transform: translateY(150px);
    transition: all ease 0.25s;
  }
  .asset--card--text{
    background-color: rgba(255, 255, 0, 0);
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    max-height: 70px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .asset--card--text h2{
    /* background-color: rgba(0, 128, 128, 0.348); */
    width: fit-content;
    text-align: left;
    margin: 0;
    word-break: break-all;
    text-overflow: ellipsis;
    font-weight: bold;
    text-shadow:1px 1px 4px white;
    color: var(--bg);
  }
  .asset--card--btn{
    background-color: rgb(27, 27, 27);
    /* position: absolute; */
     
    border: none;
    color: var(--font);
    
    font-family: var(--font--fam);
    
    font-weight: 300;
    /* margin-top: 20px; */
    transition: box-shadow 0.3s ease, text-shadow 0.3s ease, scale 0.3s ease;
    
}
.asset--btn--size--1{
  bottom: 10px;
  width: 155px;
      height: 35px;
      border-radius: 50px;
  font-size: 15px;
  margin-bottom: 5px;
}
.asset--btn--size--2{
  bottom: 10px;
width: 235px;
    height: 40px;
    border-radius: 50px;
font-size: 15px;
margin-bottom: 10px;
}
.asset--btn--size--3{
  bottom: 10px;
width: 315px;
    height: 50px;
    border-radius: 50px;
font-size: 20px;
margin-bottom: 15px;
}
.asset--card--btn:hover{
    transform: scale(1.01); /* Increase the scale on hover */
    box-shadow: rgba(84, 84, 84, 0.845) 0px 1px 2px;
    text-shadow: rgba(212, 210, 210, 0.533) 0px 1px 2px;
}
.asset--card--cont:hover .asset--card--info--cont{
  transform: translateY(0px);
  transition: all ease 0.25s;
}
.asset--card--info--cont{

}


.coll--card--v2--cont{
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  z-index: 10;
  height: 370px;
  position: relative;
  flex-basis: fit-content;
  /* width: fit-content; */
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 10px;
  
}
.coll--card--v2{
  display: flex;
  height: 350px;
  position: relative;
  width: fit-content;
  overflow: hidden;
  flex-direction: column;
  border-radius: 15px;
  border: 2.5px solid rgba(3, 3, 3, 0.305);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.268);
  transition: all ease 0.25s;
  flex-basis: content;
flex-shrink: 0;
flex-grow: 0;
min-width: 300px;
}
.coll--card--v2--img{
  height: 100%;
    width: 100%;
    position: relative;
}
.coll--card--v2--img img{
  height: 450px;
    scale: 1.01;
}

.coll--card--v2--title--cont{
  display: flex;
  position: absolute;
  bottom: 0;
  background-color: rgba(50, 50, 50, 0.277);
  width: 100%;
  max-height: 204px;
  min-height: 100px;
  /* border-radius: 10px; */
  overflow: hidden;
  align-items: center;
  backdrop-filter: blur(6px);
  z-index: 16;
  padding-left: 20px;

  
}
.coll--card--v2--title{
  color: white !important;
  font-weight: bold;
}
.coll--card--row{
  padding: 13px;
  background-color: rgb(255, 255, 255);
  width: 1318px;
  height: 367px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.303);
}
.coll--card--img--cont{
  display: flex;
  position: absolute;
  bottom: 0;
  background-color: rgba(50, 50, 50, 0.277);
  width: 100%;
  height: 204px;
  border-radius: 10px;
  overflow: hidden;
  
}
.coll--card--grid--cont{

  border-radius: 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}

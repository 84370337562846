.profile--cont{
    display: flex;
    width: 98%;
    height: 100%;
    flex-flow: row !important;
    position: relative;
    margin-left:30px;
    
    
}
.left--profile--cont{
    position: absolute;
    /* background-color: rgba(128, 128, 128, 0.2); */
    
    height: 80%;
    bottom: 0;
    left: 0;
    width: 30%;
    border-radius: 20px 20px 0px 0px;

    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    width: 403px;
}
.left--profile--info--cont{
    display: flex;
    /* background-color: teal; */
    flex-direction:column;
    width: 100%;
    align-items: center;
}
.profile--avatar--name{
    width: 100%;
    /* background-color: thistle; */
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}
.profile--avatar--cont{
    height: 150px;
    width: 150px;
    background-color: gray;
    border-radius: 50%;
    border: 2px solid black;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile--edit--btn{
    border-radius: 50px;
    
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    transform: translateY(5px) ;
    background-color: black;
    color: white;
    padding: 5px 15px ;
    font-size: 12.5px;
    opacity: 0.5;
    backdrop-filter: blur(0.4);
    border: 2px solid black;
}
.profile--wallet--btn{
    border-radius: 50px;
    border: 1.5px solid black;
    box-sizing: border-box;
    /* transform: translateY(5px) ; */
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 2.5px 5px ;
    font-size: 12.5px;
}
.left--profile--text--cont{
    margin-left: 20px;
    display: flex;
    flex-direction: column;

}
.left--profile--text--cont h4{
    margin-bottom: 5px;
}
.profile--socials--cont{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 25px;
    justify-content: center;
}
.profile--socials--btn{
    border-radius: 50px;
    border: 1.5px solid black;
    box-sizing: border-box;
    /* transform: translateY(5px) ; */
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 2.5px 5px ;
    font-size: 12.5px;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    
}
.profile--bio--cont{
    display: flex;
    width: 80%;
    flex-direction: column;
    
}
.profile--bio--cont h3{
border-bottom: 1px solid black;
width: 100%;
margin-bottom: 5px;
}
.profile--bio--cont p{
    border-bottom: 1px solid black;
    width: 100%;
    margin: 0;
    font-size: 15px;
    height: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    }

.right--profile--cont{
    /* background-color: red; */
    height: 80%;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 70%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 25px;
    padding-top: 25px;  
    overflow-x: hidden;
    overflow-y: scroll;  
}
.right--profile--inner--box{
    background-color: rgba(255, 255, 255, 0.194);
    width: 100%;
    margin: 0;
}
.right--profile--inner--box h3{
    width: 95%;
    /* background-color: tan; */
    border-bottom: 1.5px solid grey;
    color: gray;
    font-size: 25px;
    font-weight: 400;
    margin:0;
    margin-bottom: 15px;
}
.right--profile--content--cont--row{
    height: 300px;
    /* background-color: rgba(255, 255, 255, 0.668); */
    display: flex;
    overflow-x: scroll ;
    width: 100%;
    gap: 20px;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 45px;
}
.right--profile--content--cont--row--2{
    height: 400px;
    /* background-color: rgba(255, 255, 255, 0.668); */
    display: flex;
    overflow-x: scroll ;
    width: 100%;
    gap: 20px;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 45px;
}






.user--cont{
    display: flex;
    width: 98%;
    height: 100%;
    flex-flow: column !important;
    position: relative;
    margin-left:30px;
}
.top--user--cont{
    position: absolute;
    background-color: rgba(128, 128, 128, 0.2);
    height: 80%;
    bottom: 0;
    left: 0;
    width: 30%;
    border-radius: 20px 20px 0px 0px;

    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    width: 403px;
}
.top--user--info--cont{
    display: flex;
    /* background-color: teal; */
    flex-direction:column;
    width: 100%;
    align-items: center;
    /* margin-top: 100px; */
    position: relative;
    /* background-color: beige; */
    margin-top: 120px;
    justify-content: center;
}
.user--avatar--name{
    width: 100%;
    /* background-color: thistle; */
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}
.user--title--cont{
    width: 100%;
    min-height: 50px;
    max-height: 100px;
    position: sticky;
    top:0;
    /* background-color: rebeccapurple; */
    color:var(--font);
    text-transform: uppercase;
    justify-content: center;
    margin: 0px !important;
    /* padding-left: 15px; */
}
.user--title--cont h2{
    width: fit-content;
    text-align: center;
    /* background-color: rebeccapurple; */
    margin: 0px auto !important;
    font-weight: 700;
    font-size: 40px;
}
.user--avatar--cont{
    height: 150px;
    width: 150px;
    background-color: gray;
    border-radius: 50%;
    border: 2px solid black;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user--edit--btn{
    border-radius: 50px;
    
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    transform: translateY(5px) ;
    background-color: black;
    color: white;
    padding: 5px 15px ;
    font-size: 12.5px;
    opacity: 0.5;
    backdrop-filter: blur(0.4);
    border: 2px solid black;
}
.user--wallet--btn{
    border-radius: 50px;
    border: 1.5px solid black;
    box-sizing: border-box;
    /* transform: translateY(5px) ; */
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 2.5px 5px ;
    font-size: 12.5px;
}
.top--user--text--cont{
    /* margin-left: 20px; */
    display: flex;
    flex-direction: column;
    width: 90% !important;
    background-color: var(--secondary--shadow);
    backdrop-filter: blur(5px);
    margin-top: 10px;
    border-radius: 20px;
}
.user--socials--cont{
    display: flex;
        align-items: center;
        gap: 10px;
        height: 29px;
        width: fit-content;
        margin: auto;
        /* background-color: tan; */
        /* margin-top: 25px; */
        justify-content: center;
    }
    .user--socials--btn{
       border-radius: 50px;
        border: 1.5px solid var(--font);
        box-sizing: border-box;
        /* transform: translateY(5px) ; */
        background-color: var(--bg--shadow);
        color: var(--font);
        padding: 2.5px 5px ;
        font-size: 12.5px;
        width: 29px;
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
    }


.user--bio{
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    
}
.user--socials--cont{
    display: flex;
    align-items: center;
    gap: 10px;
    /* margin-top: 25px; */
    justify-content: center;
    /* background-color: yellow; */
}
.user--socials--btn{
    border-radius: 50px;
    border: 1.5px solid black;
    box-sizing: border-box;
    /* transform: translateY(5px) ; */
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 2.5px 5px ;
    font-size: 12.5px;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    
}
.user--bio--cont{
    display: flex;
    width: 80%;
    flex-direction: column;
    
}
.user--bio--cont h3{
border-bottom: 1px solid black;
width: 100%;
margin-bottom: 5px;
}
.user--bio--cont p{
    border-bottom: 1px solid black;
    width: 100%;
    margin: 0;
    font-size: 15px;
    height: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    }

.bottom--user--cont{
    /* background-color: red; */
    height: 60%;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 25px;
    padding-top: 25px;  
    overflow-x: hidden;
    /* overflow-y: scroll;   */
}
.bottom--user--inner--box{
    background-color: rgba(255, 255, 255, 0.194);
    /* width: 100%; */
    margin: 0;
}
.bottom--user--inner--box h3{
    /* width: 95%; */
    /* background-color: tan; */
    border-bottom: 1.5px solid grey;
    color: gray;
    font-size: 25px;
    font-weight: 400;
    margin:0;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    text-align: center;
}
.right--user--content--cont--row{
    height: 300px;
    /* background-color: rgba(255, 255, 255, 0.668); */
    display: flex;
    overflow-x: scroll ;
    width: 100%;
    gap: 20px;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 45px;
}
.right--user--content--cont--row--2{
    height: 400px;
    /* background-color: rgba(255, 255, 255, 0.668); */
    display: flex;
    overflow-x: scroll ;
    width: 100%;
    gap: 20px;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 45px;
}


.paginated--purchased--cont{
    padding-top: 50px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
}
.paginated--asset--cont{
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    gap: 10px;
    position: relative;
    margin: 0px 25px;
    box-sizing: border-box;
}
.paginated--page--btns{
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-size: 20px;
    height: 40px;
}
.paginated--btns--top{

    top: 0;
}
.paginated--btns--bot{

    bottom: 0;
}
.paginated--page--btns h3{
    opacity: 0.7;
    font-weight: 400;
    font-size: 15px;
}
.paginated--page--btns button{
    background-color: var(--font--shadow);
    border: none;
    min-width: 30px;
    max-width: 60px;
    height: 30px;
    font-size: 20px;
    font-weight: 800;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.currentPageBtn{
    background-color: var(--primary) !important;
    color: var(--font);
}

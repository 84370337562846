.apply--page--cont{
    display: flex;
    flex-direction: column;
    /* justify-content: ; */
    overflow-y: scroll;
    align-items: center;
    padding-top: 200px;
    padding-bottom: 300px;
    height:fit-content ;
}

.apply--page--cont label{
display: flex;
flex-direction: column;
font-size: 25px;
font-weight: bold;
font-family: var(--font--fam);
}

.apply--page--input{
    padding: 5px;
    width: 80vw;
    max-width: 1000px;
    height: 40px;
    border-radius: 1000px;
    border: 2px solid black;
    font-size: 20px;
    padding-left: 20px;
    }
    .margin-top-15{
      margin-top: 15px;
    }
  .apply--page--textarea{
    /* padding: 5px; */
    width: 80vw;
    min-width: 500px;
    max-width: 1000px;
    min-height: 200px;
    border-radius: 30px;
    border: 2px solid black;
    font-size: 20px;
    padding: 10px 20px;
    font-family: var(--font--fam);
  }
    .input--error{
        border: 2px solid var(--tertiary) !important;
        box-shadow: 0px 2px var(--tertiary);
        }
.error{
    font-size: 23px;
    color: var(--tertiary);
}
.imgdrop--title{
    display: flex;
flex-direction: column;
font-size: 25px;
font-weight: bold;
font-family: var(--font--fam);
}
.dropzone {
    border: 3px dashed #ccc;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
    border-radius: 35px;
    height: 200px;
    padding-bottom: 50px;
  }
  
  .dropzone.dragging {
    border-color: #84a9ac;
  }
  
  .dropzone label {
    display: block;
  }
  
  .dropzone img {
    max-width: 100%;
    max-height: 200px;
    margin-top: 1rem;
  }
  
  .dropzone span {
    display: block;
    font-size: 1rem;
    color: #888;
  }
  
  .apply--input--note{
    transform: none;
    margin: 0;
    padding: 0;
  }
  
.statement--check--cont{
  display: flex;
  align-items:baseline;
  width: 80vw;
  overflow: hidden;
  max-width: 1000px;
    /* height: 40px; */
    margin-top: 25px;
    /* border-radius: 1000px; */
    /* border: 2px solid black; */
}
.statement--check--cont input{
  /* background-color: yellow; */
  /* padding: 5px; */
  width: 100px;

  /* font-size: 100px; */
}
.statement--check--cont label{
  font-weight: 400;
  font-size: 20px;
}
.apply--submit{
  padding: 5px;
    width: 80vw;
    max-width: 1000px;
    height: 50px;
    border-radius: 1000px;
    border: 2px solid black;
    font-size: 20px;
    padding-left: 20px;
  margin-top: 15px;
  background-color: black;
  color: white;
}





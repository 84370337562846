.img--cont--cont{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(242, 242, 242, 0);
    z-index: 1;
}
.img--cont{
    display: flex;
    align-items: center;
    justify-content: center; 
    border-radius: 5000px;
    width: 50px;
    height: 50px;
    border: solid black 3px;
    position: relative;
    overflow: hidden;
    background-color: red;
    z-index: 2;
}

.img--cont > img{
    position: absolute;
    left: 0;
    width: 100%;
    transform: scale(1.2);
    z-index: 3;
}
.asset--page--cont{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    position: relative;
    /* background-color: yellow; */
}
.asset--page--cont StyledTitleContainer2{
    margin-top: 50px;
}
.asset--content--cont{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.asset--img-n-tags{
    display: flex;
    flex-direction: column;
    /* width: 693px; */
    align-items: center;
    justify-content: flex-end;
    /* position: absolute; */
    /* left: 0; */
}
.asset--page--img--cont{
    display: flex;
    position: relative;
    width: fit-content;
    overflow: hidden;
    flex-direction: column;
    border-radius: 5px;
    /* border: 2px solid rgba(255, 255, 255, 0.585); */
    /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.268); */
    /* transition: all ease 0.25s; */
    /* flex-basis: content; */
    /* flex-shrink: 0; */
    /* flex-grow: 0; */
  
  }
  .asset--page--img--size{
    
  }
  .asset--page--img{
    /* position: absolute; */
    /* left: 0; */
    /* height: 100%; */
    
    /* height: 400px !important; */
    
    position: relative;
    
    
  }
  .asset--page--img img{
    scale: 1.01;
    width: 100%;
    height: 500px ;
    
  }


.asset--img--cont{
    width: fit-content;
    height: 500px;
    display: flex;
    overflow: hidden;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgba(32, 32, 32, 0.341);
   
}

.asset--img--cont img{
    position: absolute;
    width: 100%;
    left: 0%;
    transform: scale(1.01);
}

.asset--tags--cont{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}
.asset--tags--cont span{
    font-size: 14px;
    border:1px solid var(--font);
    color: var(--font);
    padding:  2px 15px;
    padding-bottom: 2px ;
    border-radius: 50px;
}
.asset--details--cont{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* background-color: blue; */
    width: 526px;
    height: 500px;
    margin-left: 25px;
    position: relative;
}
.asset--author{
    margin: 0;
    font-size: 16px;
    color: var(--tertiary);
    font-weight: 400;
}
.asset--name{
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    color: var(--font);
}
.asset--description{
    margin: 0;
    font-size: 14px;
    height: 125px;
    color: var(--font--shadow);
}
.asset--price--cont{
    margin: 0;
}
.asset--price--cont p{
    margin: 0;
    font-size: 14px;
    opacity: 0.5;
    transform: none;
}
.asset--price--cont h4{
    margin: 0;
    transform: none;
    opacity: 1;
    font-size: 24px;
}

.asset--license--cont{
    margin-top: 25px;
    display: block;
}
.asset--license--cont p{
    margin: 0;
    font-weight: 600;
    margin: 0;
    font-size: 14px;
    opacity: 0.5;
    transform: none;

}
.license--btns--cont {
    list-style-type: none;
    margin:0;
    margin-top: 10px;
    padding: 0;
    
  }
  
  .license--btns--cont li {
    float: left;
    margin: 0 5px 0 0;
    width: 159px;
    height: 40px;
    position: relative;
    
  }
  
  .license--btns--cont label,
  .license--btns--cont input {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  
  .license--btns--cont input[type="radio"] {
    opacity: 0.01;
    z-index: 100;
  }
  
  .license--btns--cont input[type="radio"]:checked+label,
  .Checked+label {
    background: rgb(0, 0, 0);
    color: white;
  }
  .license--btns--cont input[type="radio"]:disabled+label,
  .Checked+label {
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    opacity: 0.3;
  }

  .license--btns--cont label {
    padding: 5px;
    border: 1.5px solid var(--font);
    cursor: pointer;
    z-index: 90;
  }
  
  .license--btns--cont label:hover {
    background: #DDD;
  }

  .asset--title--cont{
    /* position: absolute; */
    /* top: 0; */
    z-index: 100;
    width: 100%;
    height: 150px;
    color: var(--font);
    text-align: center;
    /* background-color: tan; */
    /* padding-top: 100px; */
  }
  .asset--title--cont h2{
    font-size: 40px ;
    font-weight: 900;
  }
.asset--purchase--btn--cont{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    flex-direction: column;
}
.asset--purchase--btn--cont p {
    margin: 5px;
    font-size: 13px;
    font-weight: 500;
    color: var(--font);
    opacity: 0.5;
}
  .purchase--btn{
    background-color: rgb(0, 0, 0);
    position: relative;
     /* bottom: 10px; */
    border: none;
    color: white;
    width: 515px;
    height: 50px;
    border-radius: 50px;
    font-family: var(--font--fam);
    font-size: 20px;
    font-weight: 300;
    /* margin-top: 20px; */
    transition: box-shadow 0.3s ease, text-shadow 0.3s ease, scale 0.3s ease;
}



.asset--popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg--shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 400;
}

.asset--popup {
  background-color: var(--bg);
  border: 2px solid var(--bg--shadow);
  padding: 20px;
  border-radius: 40px;
  box-shadow: 0px 2px 8px var(--font--shadow);
  text-align: center;
  width: 784px;
  height: 518px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.asset--popup a{
  order: 1;
  color: var(--tertiary);
}
.asset--popup h2{
  order: 2;
  font-weight: bold;
  font-size: 32px;
  text-transform: uppercase;
}
.popup--text{
  order: 3;
  font-size: 20px;
  text-align: left;
  text-transform: capitalize;
  width: 561px;
  min-height: 150px;
  padding-bottom: 25px;
}
.asset--popup--bot{
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
}
.asset--popup--note{
  order: 4;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 35px;
  justify-content: center;
  overflow: hidden;
  gap: 5px;
  opacity: 0.7;
  margin-bottom: 5px;
}
.asset--popup--note p{
  font-size: 12px;
  width: 510px;
  text-align: left;
  margin: 0;
}
.asset--popup--note--icon{
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  /* background-color: yellow; */
}
.asset--popup--btns{
  width: 100%;
  height: 98px;
  order: 5;
  margin: 5px;
  /* position: absolute;
  bottom: 20px;
  left: 0; */
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  /* background-color: aqua; */
}
.popup--btn{
  width: 516px;
  height: 44px;
  border: solid 2px var(--font);
  border-radius: 50px;
  box-sizing: border-box;
  font-size: 18px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-weight: bold; */
}

.btn--primary{
  background-color: var(--font);
  color: var(--bg)
}
.btn--secondary{
  background-color: var(--bg);
  color: var(--font);
}

 .footer--cont--asset{
  /* position: absolute; */
  width: 100%;
  /* bottom: 0;   */
  display: none;
 }  